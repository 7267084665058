.nav {
    position: relative;

    color: #fff;
}

.nav__icon {
    padding: 10px;
    border-radius: 100px;

    background: rgba(255, 255, 255, 0.8);

    line-height: 0;

    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 10;

    cursor: pointer;

    transition: all 0.2s ease;
}

.nav--visible .nav__icon {
    left: 260px;
}

.nav__icon svg {
    width: 30px;
}

.nav__icon path {
    fill: #243f42;
}

.nav__content {
    width: 250px;
    height: 100vh;
    padding: 20px;

    background: rgb(41, 134, 171);

    position: fixed;
    top: 0;
    left: -250px;
    z-index: 10;

    transition: all 0.2s ease;
}

.nav--visible .nav__content {
    left: 0;
}

.nav__title {
    margin-top: 25px;

    font-size: 14px;
    font-weight: 200;
    text-transform: uppercase;
}

.nav li {
    display: block;
}

.nav__button {
    width: 100%;
    border: 0;
    padding: 10px;

    text-align: left;
    color: #fff;
    font-size: 1em;
    line-height: 1;
    font-weight: bold;

    background: none;

    cursor: pointer;

    transition: background 0.2s ease;
}

.nav__button:hover {
    color: #fff;

    background: rgba(255, 255, 255, 0.1);
}
