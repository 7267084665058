.banner {
    padding: 80px 20px 50px 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #fff;
    text-align: center;
    line-height: 1.2;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.banner__profile {
    width: 120px;
    border-radius: 1000px;
    border: 5px solid #fff;
    margin-bottom: 20px;

    @media screen and (min-height: 400px) {
        width: 150px;
    }

    @media screen and (min-height: 450px) and (orientation: landscape) {
        width: 200px;
    }
}

.banner--darken .banner__text {
    padding: 20px;
    border-radius: 5px;

    background: rgba(0, 0, 0, 0.4);
}

.banner__intro {
    font-size: 20px;
    font-style: italic;
}

.banner__name {
    margin-bottom: 0;

    font-size: 28px;
    font-weight: normal;

    @media screen and (min-width: 400px) {
        font-size: 40px;
    }
}
