.typewriter {
    min-height: 20px;
    padding-right: 2px;
    border-right: 2px solid #fff;

    white-space: nowrap;

    animation: blink 1.2s step-end infinite;
}

@keyframes blink {
    0% {
        border-color: transparent;
    }
    50% {
        border-color: #fff;
    }
    100% {
        border-color: transparent;
    }
}
