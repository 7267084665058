.imageModal {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.5);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.imageModal__content {
    padding: 20px;
    margin: 20px;

    background: #fff;

    position: relative;
}

.imageModal__close {
    width: 30px;
    height: 30px;
    padding-left: 10px;
    padding-top: 5px;

    border: 1px solid #333;
    border-radius: 100px;

    background: #fff;

    line-height: 1;

    position: absolute;
    top: -10px;
    right: -10px;
}

.imageModal img {
    max-height: 80vh;

    pointer-events: none;
}
