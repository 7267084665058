*,
::before,
::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: #fff;

    color: #243f42;
    font-size: 16px;
    line-height: 1.4;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 200;
}

h2 {
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 200;
}

p {
    margin-bottom: 20px;
}

p:last-child {
    margin-bottom: 0;
}

a {
    color: #4284ee;
    text-decoration: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

img {
    width: 100%;
    height: auto;
}

iframe {
    width: 100%;
    border: 0;
    min-height: 50vh;
    overflow-y: hidden;
}

.enlarge {
    cursor: zoom-in;
}

.repeatingContent {
    padding: 50px 20px;
}

.repeatingContent h1 {
    margin-bottom: 50px;

    text-align: center;
}

.repeatingContent__list {
    display: flex;
    flex-direction: column;
    align-items: center;
}
